import React from 'react';
import styled, { css } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { beige, menuTextColor, pinkOrange } from '../../utils/colors';
import { mobile } from '../../utils/media';
import Sidebar from '../sidebar/Sidebar';
import imageArmonia from '../../images/logo_name.svg';

const Container = styled.div`
  display: none;
  ${mobile(css`
    display: flex;
    ${p =>
      p.showScrollMenuMobile
        ? `top: 0;position: fixed;width: 100%;z-index: 100000;box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.1);`
        : null}
  `)}
`;
const HeaderContainer = styled.div`
  flex: 1;
  position: relative;
  justify-content: center;
  background-color: ${beige};
`;

const Content = styled.div`
  display: flex;
  flex-basis: 80%;
  justify-content: center;
`;

const LogoContainer = styled.div`
  padding-top: 20px;
  padding-bottom: 15px;
  text-align: center;
`;

const MenuButton = styled.div`
  position: absolute;
  left: 30px;
  top: 50%;
  margin-top: -13px;
  cursor: pointer;
  color: ${menuTextColor};
  ${mobile(css`
    display: flex;
    font-size: 2em;
  `)}
  &:hover {
    color: ${pinkOrange};
  }
`;

const LogoImage = styled.img`
  width: 50%;
`;

const HeaderMobile = props => {
  return (
    <Container id="menuMobile" showScrollMenuMobile={props.showScrollMenuMobile}>
      <HeaderContainer>
        <MenuButton onClick={() => props.onToggleMenu()}>
          <FontAwesomeIcon icon="bars" />
        </MenuButton>
        <Content>
          <LogoContainer>
            <LogoImage src={imageArmonia} alt="Logo armonia psicologia"/>
          </LogoContainer>
        </Content>
      </HeaderContainer>
      <Sidebar show={props.showMenu} onToggleMenu={props.onToggleMenu} />
    </Container>
  );
};

export default HeaderMobile;
