import React from 'react';
import styled, { css } from 'styled-components';
import { ArmoniaSection, Content, TituloSectionSubrayado } from '../../theme/Components';
import { celeste, turquesaClaro, turquesaOscuro } from '../../utils/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { mobile } from '../../utils/media';
import Swiper from 'react-id-swiper';

const testimoniosData = [
  {
    texto:
      'La terapia me ha ayudado a superar una fase triste de mi vida. He aprendido a lidiar con mis sentimientos depresivos. Me he dado cuenta especialmente de la importancia de las sesiones regulares, ya que interactuar con la doctora Calderón regularmente me ha ayudado a mantener los beneficios que obtengo de las sesiones.',
    autor: 'Anónimo, Quito'
  },
  {
    texto:
      'Ser padres a veces es complicado, más para mi como madre soltera, necesitaba de consejos y he comprendido que está bien pedir ayuda profesional. Como parte de mi experiencia, en lugar de poner en riesgo la relación entre usted y sus hijos, es posible que desee probar un poco de terapia. La Dra. Calderón es una persona increíble, que ayudó a mi familia a lidiar con los problemas de adolescencia de mi hijo.',
    autor: 'Anónimo, Ambato'
  },
  {
    texto:
      'Tuve una buena sesión con Carla y estoy muy agradecido con ella por ayudarme a sentirme mejor. Abordó mis inquietudes y pude sentir la diferencia en una sola sesión.',
    autor: 'Anónimo, Quito'
  },
  {
    texto:
      'Para mi fue una buena experiencia. Pensaba que no había una solución para tantos problemas en nuestro matrimonio, pero hasta ahora, la doctora Calderón nos ha demostrado a mi esposo y a mi que estamos equivocados y que con comunicación podemos resolver nuestras diferencias.',
    autor: 'Anónimo, Ibarra'
  }
];

const Texto = styled.p`
  font-size: 1.2em;
  font-style: italic;
  line-height: 1.5em;
  padding: 3.1em 3.9em;

  ${mobile(css`
    font-size: 0.9em;
    padding: 1.8em 2.5em;
  `)}
`;

const OpenQuote = styled(FontAwesomeIcon)`
  color: ${turquesaOscuro};
  font-size: 1.1em;
  display: block;
  position: absolute;
  left: 1.5em;
  font-style: normal;
  top: 2.8em;
  ${mobile(css`
    top: 1.7em;
    left: 0.9em;
    font-size: 0.8em;
  `)}
`;

const CloseQuote = styled(FontAwesomeIcon)`
  color: ${turquesaOscuro};
  font-size: 1.1em;
  display: block;
  position: absolute;
  right: 1.5em;
  font-style: normal;
  bottom: 2.5em;
  ${mobile(css`
    right: 1em;
    bottom: 1.7em;
    font-size: 0.8em;
  `)}
`;

const Contenedor = styled.div`
  width: 100%;
`;

const TestimonioContenedor = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #fff;
  flex-direction: column;
  margin-bottom: 50px;
`;

const Autor = styled.span`
  position: absolute;
  bottom: 2em;
  ${mobile(css`
    bottom: 0.4em;
    font-size: 0.8em;
  `)}
`;

class Testimonios extends React.Component {
  renderTestimonios = () => {
    return testimoniosData.map((el, index) => {
      return (
        <TestimonioContenedor key={index}>
          <OpenQuote icon="quote-left" />
          <Texto>{el.texto}</Texto>
          <CloseQuote icon="quote-right" />
          <Autor>{el.autor}</Autor>
        </TestimonioContenedor>
      );
    });
  };

  render() {
    const params = {
      slidesPerView: 2,
      spaceBetween: 70,
      autoplay: {
        delay: 8500,
        disableOnInteraction: false
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true
      },
      breakpoints: {
        1024: {
          slidesPerView: 2,
          spaceBetween: 70
        },
        768: {
          slidesPerView: 1,
          spaceBetween: 50
        },
        640: {
          slidesPerView: 1,
          spaceBetween: 20
        },
        320: {
          slidesPerView: 1,
          spaceBetween: 10
        }
      }
    };
    return (
      <ArmoniaSection id="testimonios" color={celeste}>
        <Content>
          <TituloSectionSubrayado underlineColor={turquesaClaro}>Testimonios</TituloSectionSubrayado>
          <Contenedor>
            <Swiper {...params}>{this.renderTestimonios()}</Swiper>
          </Contenedor>
        </Content>
      </ArmoniaSection>
    );
  }
}

export default Testimonios;
