import React from 'react';
import styled from 'styled-components';
import { grayBrown, pinkOrange, turquesaOscuro } from '../../utils/colors';

const Container = styled.div`
  background-color: ${grayBrown};
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: #fff;
  font-size: 0.9em;
  padding: 1.5em 0;
`;

const Link = styled.a`
  text-decoration: none;
  margin-left: 5px;
  color: ${pinkOrange};
  &:hover {
    color: ${turquesaOscuro};
  }
`;

const CopyrightBar = props => {
  return (
    <Container>
      Developed by
      <Link rel="noopener noreferrer" href="https://thexap.tech" target="_blank">
        thexap
      </Link>
    </Container>
  );
};

export default CopyrightBar;
