import React from 'react';
import styled, { css } from 'styled-components';
import { menuTextColorHover, celeste, turquesaOscuro } from '../../utils/colors';
import { phone, mobile } from '../../utils/media';
import { ArmoniaSection, Content, TituloSectionSubrayado, Boton } from '../../theme/Components';
import { CITA } from '../../utils/routesConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const steps = [
  {
    titulo: 'Paso 1',
    descripcion: 'Agenda una cita.',
    boton: 'Agendar cita',
    icono: 'calendar-alt'
  },
  {
    titulo: 'Paso 2',
    descripcion: 'Evaluamos tu preocupación, y elegimos la terapia adecuada',
    boton: 'Comenzar',
    icono: 'code-branch'
  },
  {
    titulo: 'Paso 3',
    descripcion:
      'Revisamos el progreso de los objetivos planteados para que puedas crecer hasta tu máximo potencial.',
    boton: 'Lo lograré',
    icono: 'trophy'
  }
];

const StepsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  ${mobile(css`
    justify-content: center;
  `)}
`;

const StepContainer = styled.div`
  background-color: ${celeste};
  padding: 50px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
  justify-content: space-between;
  margin-bottom: 20px;
  width: 100%;
  ${phone(css`
    padding: 25px;
    margin-right: 0px;
  `)}
  &:last-of-type {
    margin-right: 0px;
  }
`;

const StepTitle = styled.h4`
  font-weight: 800;
  color: ${menuTextColorHover};
  font-size: 1.39em;
  margin-top: 0px;
`;

const StepDescription = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const Icono = styled(FontAwesomeIcon)`
  color: ${turquesaOscuro};
  font-size: 70px;
  margin-bottom: 10px;
`;

const renderSteps = () => {
  return steps.map((el, index) => (
    <StepContainer key={index}>
      <Icono icon={el.icono} />
      <StepTitle>{el.titulo}</StepTitle>
      <StepDescription>{el.descripcion}</StepDescription>
      <Boton to={CITA} backgroundColor={turquesaOscuro}>
        {el.boton}
      </Boton>
    </StepContainer>
  ));
};

const TreeSteps = props => {
  return (
    <ArmoniaSection>
      <Content>
        <TituloSectionSubrayado underlineColor={turquesaOscuro}>
          Comienza con 3 sencillos pasos
        </TituloSectionSubrayado>
        <StepsContainer>{renderSteps()}</StepsContainer>
      </Content>
    </ArmoniaSection>
  );
};

export default TreeSteps;
