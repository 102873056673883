import React from 'react';
import styled from 'styled-components';
import { pinkOrange, white } from '../../utils/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Container = styled.a`
  position: fixed;
  width: 3em;
  height: 3em;
  background-color: ${pinkOrange};
  color: ${white};
  bottom: 2em;
  right: 2em;
  text-align: center;
  line-height: 3em;
  transition: all ease 1.5s;
  opacity: ${p => (p.show ? 1 : 0)};
  z-index: 9999;
`;

const GoToTop = props => {
  return (
    <Container href="#" show={props.show} aria-label="Ir al principio de la pagina">
      <FontAwesomeIcon icon="angle-up" />
    </Container>
  );
};

export default GoToTop;
