import React, { Component } from 'react';
import PromesaComponent from '../components/promesa/Promesa';
import TreeSteps from '../components/treeSteps/TreeSteps';

class Promesa extends Component {
  render() {
    return (
      <>
        <PromesaComponent />
        <TreeSteps />
      </>
    );
  }
}
export default Promesa;
