import React from 'react';
import styled from 'styled-components';
import { turquesaOscuro, white } from '../../utils/colors';
import { sidebarWidth } from '../../utils/sizes';
import { Link } from 'react-router-dom';
import { menuTree } from '../../utils/menuTree';

const Container = styled.div`
  background-color: ${turquesaOscuro};
  color: ${white};
  width: ${sidebarWidth}px;
  display: flex;
  flex: 1;
  position: fixed;
  top: 0;
  left: ${p => (p.show ? 0 : -sidebarWidth)}px;
  height: auto;
  bottom: 0;
  overflow: hidden;
  overflow-y: scroll;
  z-index: 10003;
  padding: 0px;
  transition: left 0.3s linear;
`;

const Mask = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  cursor: pointer;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.6);
  ${p => p.show && `right:0px`};
`;

const MenuUl = styled.ul`
  list-style-type: none;
  flex-direction: column;
  display: flex;
  margin: 0;
  padding: 0;
  flex: 1;
`;

const MenuLi = styled.li`
  display: flex;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
`;

const MenuItem = styled(({ especial, ...rest }) => <Link {...rest} />)`
  color: ${white};
  padding: 17px 35px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1em;
  display: flex;
  flex: 1;
  ${p => (p.especial ? `background-color:rgba(0, 0, 0, 0.09)` : null)}
`;

const MenuItemNormal = styled.a`
  color: ${white};
  padding: 17px 35px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 1px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 1em;
  display: flex;
  flex: 1;
  ${p => (p.especial ? `background-color:rgba(0, 0, 0, 0.09)` : null)}
`;

const Sidebar = props => {
  return (
    <>
      <Container show={props.show}>
        <MenuUl>
          {menuTree.map(item => (
            <MenuLi key={item.id}>
              <MenuItem to={item.to} onClick={props.onToggleMenu} especial={item.especial}>
                {item.title}
              </MenuItem>
            </MenuLi>
          ))}
          <MenuItemNormal href="https://blog.armoniapsicologia.com/">BLOG</MenuItemNormal>
        </MenuUl>
      </Container>
      <Mask show={props.show} onClick={props.onToggleMenu} />
    </>
  );
};

export default Sidebar;
