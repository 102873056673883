import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { grayBrown, lightGrey, pinkOrange, darkGray } from '../../utils/colors';
import { Link } from 'react-router-dom';
import { CITA } from '../../utils/routesConstants';

const Container = styled.div`
  background-color: ${grayBrown};
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  color: ${lightGrey};
  font-size: 0.9em;
`;

const CenterContainer = styled.div`
  flex-basis: 80%;
  display: flex;
`;

const Left = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  padding: 0.88em 0 0.88em;
`;

const Right = styled.div`
  justify-content: flex-end;
  display: flex;
  flex: 1;
`;

const Text = styled.a`
  padding-left: 10px;
  text-decoration: none;
  color: inherit;
`;

const Phone = styled.div`
  align-items: center;
  display: flex;
`;

const Boton = styled(Link)`
  background-color: ${pinkOrange};
  align-items: center;
  display: flex;
  margin-left: 3em;
  padding: 0 2.4em;
  color: ${darkGray};
  font-weight: 400;
  text-decoration: none;
`;

const TopBar = props => {
  return (
    <Container>
      <CenterContainer>
        <Left>
          <FontAwesomeIcon icon="home" />
          <Text>Quito - Ecuador</Text>
        </Left>
        <Right>
          <Phone>
            <FontAwesomeIcon icon="phone" />
            <Text href="https://wa.me/593994036446" aria-label="Llama al telefono 099-403-6446">099-403-6446</Text>
          </Phone>
          <Boton to={CITA}>Agendar cita</Boton>
        </Right>
      </CenterContainer>
    </Container>
  );
};

export default TopBar;
