import React from 'react';
import styled from 'styled-components';
import { beige } from '../../utils/colors';
import Menu from './Menu';
import imageArmonia from '../../images/logo.svg';

const Container = styled.div`
  background-color: ${beige};
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 20px 0px;
`;

const ContentWrap = styled.div`
  flex-basis: 80%;
  display: flex;
`;

const Logo = styled.a`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  width: 70%;
  max-height: 145px;
`;

const MenuLogo = props => {
  return (
    <Container id="menuLogo">
      <ContentWrap>
        <Logo href="/" aria-label="Ir a inicio de Armonia Psicologia">
          <LogoImage src={imageArmonia} alt="logo armonia psicologia" />
        </Logo>
        <Menu />
      </ContentWrap>
    </Container>
  );
};

export default MenuLogo;
