import React, { Component, Suspense } from 'react';
import styled, { keyframes } from 'styled-components';
import Header from './components/header/Header';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faHome,
  faPhone,
  faCheck,
  faBars,
  faAngleUp,
  faMapMarkerAlt,
  faCalendarAlt,
  faLock,
  faBookOpen,
  faCodeBranch,
  faTrophy,
  faMobileAlt,
  faQuoteLeft,
  faQuoteRight,
  faSpinner
} from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { Route, Switch } from 'react-router-dom';
import HeaderMobile from './components/header/HeaderMobile';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Footer from './components/footer/Footer';
import CopyrightBar from './components/copyright/CopyrightBar';
import GoToTop from './components/goToTop/GoToTop';
import routes from './routes';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { pinkOrange } from './utils/colors';

library.add(
  faHome,
  faPhone,
  faCheck,
  fab,
  faBars,
  faAngleUp,
  faMapMarkerAlt,
  faCalendarAlt,
  faLock,
  faBookOpen,
  faCodeBranch,
  faTrophy,
  faMobileAlt,
  faQuoteLeft,
  faQuoteRight,
  faSpinner
);

const NotFound = React.lazy(() => import('./components/404/NotFound'));
const spin = keyframes`
from{
  transform: rotate(0deg);
}
to{
  transform: rotate(360deg)
}
`;
const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  animation: ${spin} 2s linear infinite;
  padding: 3em;
`;

const Icono = styled(FontAwesomeIcon)`
  color: ${pinkOrange};
  font-size: 70px;
  margin-bottom: 20px;
`;

class App extends Component {
  state = {
    showMenu: false,
    showScrollMenu: false,
    showScrollMenuMobile: false
  };

  loading = () => (
    <Loading>
      <Icono icon={'spinner'} />
    </Loading>
  );

  constructor(props) {
    super(props);
    AOS.init({
      duration: 1000
    });
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu });
  };

  handleScroll = event => {
    const menu = document.getElementById('menuLogo');
    const menuMobile = document.getElementById('menuMobile');
    const menuTamano = menu.offsetTop + 160;
    if (window.pageYOffset >= menuTamano) {
      this.setState({ showScrollMenu: true });
    } else {
      this.setState({ showScrollMenu: false });
    }

    const menuTamanoMobile = menuMobile.offsetTop + 100;

    if (window.pageYOffset >= menuTamanoMobile) {
      this.setState({ showScrollMenuMobile: true });
    } else {
      this.setState({ showScrollMenuMobile: false });
    }
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  render() {
    return (
      <div className="App">
        <Header showScrollMenu={this.state.showScrollMenu} />
        <HeaderMobile
          showMenu={this.state.showMenu}
          onToggleMenu={this.toggleMenu}
          showScrollMenuMobile={this.state.showScrollMenuMobile}
        />
        <Suspense fallback={this.loading()}>
          <Switch>
            {routes.map((route, index) => (
              <Route
                key={index}
                path={route.to}
                name={route.titulo}
                exact={route.exact}
                render={props => <route.component {...props} />}
              />
            ))}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
        <Footer />
        <CopyrightBar />
        <GoToTop show={this.state.showScrollMenu} />
      </div>
    );
  }
}

export default App;
