import React from 'react';
import styled, { css } from 'styled-components';
import { ArmoniaSection, Content, TituloSectionSubrayado } from '../../theme/Components';
import { pinkOrange, menuTextColorHover, beige } from '../../utils/colors';
import { mobile, phone } from '../../utils/media';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Contenedor = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  flex: 1;
  width: 100%;
  justify-content: space-between;
  ${mobile(css`
    justify-content: center;
  `)}
`;

const PromesaContainer = styled.div`
  padding: 30px;
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 250px;
  justify-content: space-between;
  width: 100%;
  ${phone(css`
    padding: 25px;
    margin-right: 0px;
    padding: 10px;
  `)}
  &:last-of-type {
    margin-right: 0px;
  }
`;

const PromesaTitle = styled.h4`
  font-weight: 800;
  color: ${menuTextColorHover};
  font-size: 1.39em;
  margin-top: 0px;
  text-transform: capitalize;
  margin-bottom: 10px;
  text-align: center;
`;

const PromesaDescription = styled.div`
  text-align: center;
  margin-bottom: 40px;
`;

const Icono = styled(FontAwesomeIcon)`
  color: ${pinkOrange};
  font-size: 70px;
  margin-bottom: 10px;
`;

const Promesa = props => {
  return (
    <ArmoniaSection id="promesa" color={beige}>
      <Content>
        <TituloSectionSubrayado underlineColor={pinkOrange}>Mi promesa</TituloSectionSubrayado>
        <Contenedor>
          <PromesaContainer>
            <Icono icon="calendar-alt" />
            <PromesaTitle>Disponibilidad</PromesaTitle>
            <PromesaDescription>Tendrás prioridad para agendar una cita. </PromesaDescription>
          </PromesaContainer>
          <PromesaContainer>
            <Icono icon="lock" />
            <PromesaTitle>100% confidencial</PromesaTitle>
            <PromesaDescription>Total confidencialidad entre tu y tu terapeuta</PromesaDescription>
          </PromesaContainer>
          <PromesaContainer>
            <Icono icon="book-open" />
            <PromesaTitle>Experiencia</PromesaTitle>
            <PromesaDescription>
              Años de experiencia, investigación y estudio, con el compromiso a los más altos estándares
              de la práctica ética.
            </PromesaDescription>
          </PromesaContainer>
        </Contenedor>
      </Content>
    </ArmoniaSection>
  );
};

export default Promesa;
