import React from 'react';
import styled from 'styled-components';
import { menuTextColor, menuTextColorHover } from '../../utils/colors';
import { Link } from 'react-router-dom';
import { menuTree } from '../../utils/menuTree';
const MenuContainer = styled.div`
  justify-content: flex-end;
  display: flex;
  flex: 1;
`;

const MenuNav = styled.nav`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const MenuUl = styled.ul`
  list-style-type: none;
  flex-direction: row;
  display: flex;
`;

const MenuItem = styled(Link)`
  color: ${menuTextColor};
  font-size: 0.866em;
  line-height: 1.2857em;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  transition: all 0.3s ease 0s;
  &:hover {
    color: ${menuTextColorHover};
  }
`;

const MenuItemNormal = styled.a`
  color: ${menuTextColor};
  font-size: 0.866em;
  line-height: 1.2857em;
  font-weight: 400;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  background: transparent;
  transition: all 0.3s ease 0s;
  &:hover {
    color: ${menuTextColorHover};
  }
`;

const ListItem = styled.li`
  display: flex;
  align-items: center;
  padding: 10px 15px;
`;

const Menu = props => {
  return (
    <MenuContainer>
      <MenuNav>
        <MenuUl>
          {menuTree
            .filter(e => e.mobileOnly === false)
            .map(item => (
              <ListItem key={item.id}>
                <MenuItem to={item.to}>{item.title}</MenuItem>
              </ListItem>
            ))}
          <ListItem>
            <MenuItemNormal href="https://blog.armoniapsicologia.com/">BLOG</MenuItemNormal>
          </ListItem>
        </MenuUl>
      </MenuNav>
    </MenuContainer>
  );
};

export default Menu;
