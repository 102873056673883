import React from 'react';
import styled from 'styled-components';
import { beige } from '../../utils/colors';
import imageArmonia from '../../images/logo_name.svg';
import Menu from './Menu';

const Container = styled.div`
  background-color: ${beige};
  display: flex;
  flex: 1;
  justify-content: center;
  padding: 10px 0px;
  top: 0;
  position: fixed;
  width: 100%;
  z-index: 100000;
  box-shadow: 0px 3px 0px 0px rgba(0, 0, 0, 0.1);
`;
const ContentWrap = styled.div`
  flex-basis: 80%;
  display: flex;
`;

const Logo = styled.a`
  display: flex;
  align-items: center;
`;

const LogoImage = styled.img`
  height: 55px;
`;

const MenuScrollableDesktop = props => {
  return (
    <Container>
      <ContentWrap>
        <Logo href="/" aria-label="Ir a inicio de Armonia Psicologia">
          <LogoImage src={imageArmonia} alt="Logo armonia psicologia"/>
        </Logo>
        <Menu />
      </ContentWrap>
    </Container>
  );
};

export default MenuScrollableDesktop;
