import React from 'react';
import styled, { css } from 'styled-components';
import {
  sectionPaddingTop,
  sectionPaddingBottom,
  contentWidth,
  sectionPaddingTopPhone,
  sectionPaddingBottomPhone
} from '../utils/sizes';
import { mobile, phone } from '../utils/media';
import { turquesaOscuro, menuTextColorHover, white, pinkOrange } from '../utils/colors';
import { Link } from 'react-router-dom';

export const ArmoniaSection = styled.section`
  padding-top: ${sectionPaddingTop}em;
  padding-bottom: ${sectionPaddingBottom}em;
  display: flex;
  justify-content: center;
  background-color: ${p => (p.color ? p.color : `inherit`)};
  ${phone(css`
    padding-top: ${sectionPaddingTopPhone}em;
    padding-bottom: ${sectionPaddingBottomPhone}em;
  `)};
`;

export const Content = styled.div`
  display: flex;
  width: ${contentWidth}%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const TituloSection = styled.h1`
  color: ${menuTextColorHover};
  font-size: 2.2em;
  text-transform: capitalize;
  text-align: center;
  margin-top: 0em;
  ${mobile(css`
    font-size: 1.8em;
  `)};
`;

export const TituloSectionSubrayado = styled(TituloSection)`
  &::after {
    content: ' ';
    display: block;
    width: 20px;
    height: 2px;
    margin: 0.4em auto 0.55em;
    background-color: ${p => (p.underlineColor ? p.underlineColor : turquesaOscuro)};
  }
`;

export const SectionDescription = styled.div`
  font-size: 0.93em;
  font-weight: 300;
  letter-spacing: 1px;
  line-height: 1.7em;
  margin-bottom: 2em;
  max-width: 50%;
  text-align: center;
  text-transform: uppercase;
  ${mobile(css`
    max-width: 70%;
  `)};
  ${phone(css`
    max-width: 100%;
  `)};
`;

export const Boton = styled(({ backgroundColor, color, ...rest }) => <Link {...rest} />)`
  color: ${p => (p.color ? p.color : white)};
  background-color: ${p => (p.backgroundColor ? p.backgroundColor : pinkOrange)};
  border: none;
  font-size: 1em;
  line-height: 1.4em;
  border-radius: 1.66em;
  font-weight: 300;
  text-align: center;
  padding: 1em 2em;
  text-decoration: none;
  transition: all 0.3s ease 0s;
  opacity: 1;
  &:hover {
    background-color: ${p => (p.backgroundColor ? p.backgroundColor : pinkOrange)};
    opacity: 0.75;
  }
`;
