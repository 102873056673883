export const grayBrown = '#504B47';
export const lightGrey = '#B1B0AF';
export const pinkOrange = '#EDB39B';
export const darkGray = '#504B47';
export const beige = '#fcf7f1';
export const menuTextColor = ' #6f6f6f';
export const menuTextColorHover = '#33302e';
export const turquesaOscuro = '#72b5ca';
export const turquesaClaro = '#76b5c8';
export const white = '#FFFFFF';
export const celeste = '#eaf5f9';
export const separatorGray = '#e4e7e8';
export const servicioColor = '#767676';
