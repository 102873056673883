import React from 'react';
import styled from 'styled-components';
import { menuTextColor, turquesaOscuro, beige, pinkOrange, white } from '../../utils/colors';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import imageArmonia from '../../images/logo.svg';

const Container = styled.footer`
  background-color: ${beige};
  display: flex;
  justify-content: center;
  padding-top: 2em;
  padding-bottom: 3em;
  color: ${menuTextColor};
`;

const Content = styled.div`
  display: flex;
  width: 80%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LogoContainer = styled.div`
  display: flex;
  flex-basis: 50%;
  justify-content: center;
  margin-bottom: 0em;
`;

const LogoImage = styled.img`
  width: 70%;
  max-height: 130px;
`;

const Celda = styled.address`
  display: flex;
  text-align: center;
  font-style: normal;
`;

const Link = styled.a`
  color: ${pinkOrange};
  text-decoration: none;
  margin-left: 5px;

  &:hover {
    color: ${turquesaOscuro};
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin-top: 10px;
`;

const SocialItem = styled.div`
  margin: 0 0.335em 0.335em 0;
  display: flex;
`;

const SocialButton = styled.a.attrs({
  // target: '_blank'
})`
  background-color: ${pinkOrange};
  color: ${white};
  border-radius: 50%;
  width: 2.5em;
  height: 2.5em;
  line-height: 2.5em;
  transition: all 0.3s ease 0s;
  background-size: 2.65em 5.3em;
  background-position: 0 -2.65em;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: ${turquesaOscuro};
  }
`;

const Footer = props => {
  return (
    <Container>
      <Content>
        <LogoContainer>
          <LogoImage src={imageArmonia} alt="Logo armonia psicologia"/>
        </LogoContainer>
        <Celda>Quito - Ecuador</Celda>
        <Celda>
          Teléfono:
          <Link href="tel:+593994036446" aria-label="Contacame a mi celular 099-403-6446">099-403-6446</Link>
        </Celda>
       
        <ButtonsContainer>
        <SocialItem>
            <SocialButton
              rel="noopener noreferrer"  aria-label="Contacame via skype"
              href="skype:live:100b31cd3b79f2e?message"
            >
              <FontAwesomeIcon icon={['fab', 'skype']} />
            </SocialButton>
          </SocialItem>
          <SocialItem>
            <SocialButton
              target="_blank"
              rel="noopener noreferrer" aria-label="Visitanos en instagram"
              href="https://www.instagram.com/armoniapsicologiaecuador/"
            >
              <FontAwesomeIcon icon={['fab', 'instagram']} />
            </SocialButton>
          </SocialItem>
          <SocialItem>
            <SocialButton
              target="_blank"
              rel="noopener noreferrer" aria-label="Visitanos en facebook"
              href="https://facebook.com/armoniapsicologiaecuador/"
            >
              <FontAwesomeIcon icon={['fab', 'facebook']} />
            </SocialButton>
          </SocialItem>
          <SocialItem>
            <SocialButton target="_blank" rel="noopener noreferrer" aria-label="Contactame en whatsapp" href="https://wa.me/593994036446">
              <FontAwesomeIcon icon={['fab', 'whatsapp']} />
            </SocialButton>
          </SocialItem>
        </ButtonsContainer>
      </Content>
    </Container>
  );
};

export default Footer;
