//Servicios
export const SERVICIO_ESTRES = '/areas-que-trabajo/manejo-de-estres';
export const SERVICIO_ANSIEDAD = '/areas-que-trabajo/manejo-ansiedad';
export const SERVICIO_DEPRESION = '/areas-que-trabajo/manejo-depresion';
export const SERVICIO_PROBLEMAS_PAREJA = '/areas-que-trabajo/problemas-de-pareja';
export const SERVICIO_ADOLESCENTES = '/areas-que-trabajo/comunicacion-con-adolescentes';
export const SERVICIO_ADULTOS = '/areas-que-trabajo/adultos';
export const SERVICIO_PACIENTES_ONCOLOGICOS = '/areas-que-trabajo/pacientes-con-cancer';
export const SERVICIO_ADULTOS_MAYORES = '/areas-que-trabajo/adultos-mayores';

//Paginas
export const AREAS_QUE_TRABAJO = '/areas-que-trabajo';
export const ACERCA_DE = '/acercaDe';
export const CITA = '/cita';
export const CONTACTO = '/contacto';
export const INICIO = '/';
export const PROMESA = '/promesa';
export const TESTIMONIOS = '/testimonios';
