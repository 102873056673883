import { ACERCA_DE, CONTACTO, INICIO, CITA, AREAS_QUE_TRABAJO } from './routesConstants';

export const menuTree = [
  {
    id: 'inicio',
    order: 10,
    title: 'Inicio',
    to: INICIO,
    mobileOnly: false
  },
  {
    id: 'areas',
    order: 15,
    title: 'Áreas que te puedo ayudar',
    to: AREAS_QUE_TRABAJO,
    mobileOnly: false
  },
  {
    id: 'acercaDe',
    order: 20,
    title: 'Acerca de mí',
    to: ACERCA_DE,
    mobileOnly: false
  },
  {
    id: 'contacto',
    order: 30,
    title: 'Contacto',
    to: CONTACTO,
    mobileOnly: false
  },
  {
    id: 'cita',
    order: 40,
    title: 'Agendar Cita',
    to: CITA,
    mobileOnly: true,
    especial: true
  }
];
