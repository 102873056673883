import React, { Component } from 'react';
import TestimoniosComponent from '../components/testimonios/Testimonios';
import TreeSteps from '../components/treeSteps/TreeSteps';

class Testimonios extends Component {
  render() {
    return (
      <>
        <TestimoniosComponent />
        <TreeSteps />
      </>
    );
  }
}
export default Testimonios;
