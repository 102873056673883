import React from 'react';
import { AREAS_QUE_TRABAJO, INICIO, ACERCA_DE, CONTACTO, CITA, PROMESA, TESTIMONIOS } from './utils/routesConstants';
import Promesa from './pages/Promesa';
import Testimonios from './pages/Testimonios';

const Home = React.lazy(() => import('./pages/Home'));
const AboutMe = React.lazy(() => import('./pages/AboutMe'));
const Contacto = React.lazy(() => import('./pages/Contacto'));
const Cita = React.lazy(() => import('./pages/Cita'));
const AreasDeEspecialidad = React.lazy(() => import('./pages/AreasDeEspecialidad'));

const routes = [
  {
    to: INICIO,
    titulo: 'Inicio',
    component: Home,
    exact: true
  },
  {
    to: ACERCA_DE,
    titulo: 'Acerca de mi',
    component: AboutMe,
    exact: true
  },
  {
    to: AREAS_QUE_TRABAJO,
    titulo: 'Áreas con las que trabajo',
    component: AreasDeEspecialidad,
    exact: true
  },
  {
    to: `${AREAS_QUE_TRABAJO}/:id`,
    titulo: '',
    component: AreasDeEspecialidad,
    exact: true
  },
  {
    to: CONTACTO,
    titulo: 'Contacto',
    component: Contacto,
    exact: true
  },
  {
    to: CITA,
    titulo: 'Pedir Cita',
    component: Cita,
    exact: true
  },
  {
    to: PROMESA,
    titulo: 'Mi Promesa',
    component: Promesa,
    exact: true
  },
  {
    to: TESTIMONIOS,
    titulo: 'Testimonios',
    component: Testimonios,
    exact: true
  }
];

export default routes;
