import React from 'react';
import TopBar from './TopBar';
import styled, { css } from 'styled-components';
import MenuLogo from './MenuLogo';
import { mobile } from '../../utils/media';
import MenuScrollableDesktop from './MenuScrollableDesktop';

const HeaderHtml = styled.header`
  display: flex;
  flex: 1;
  flex-direction: column;
  ${mobile(css`
    display: none;
  `)}
`;

const Header = props => {
  const { showScrollMenu } = props;
  return (
    <HeaderHtml>
      <TopBar />
      <MenuLogo />
      {showScrollMenu && <MenuScrollableDesktop />}
    </HeaderHtml>
  );
};

export default Header;
